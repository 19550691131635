import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <div className="flex flex-col flex-wrap justify-start min-h-screen items-center">

    <SEO title="404: Not found" />
    <h1 className={"text-center"}><span className={"text-orange"}>404</span>: Not Found</h1>
    <p className={"text-center"}>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <Link to={"/"}>
            <Button>Return Home&nbsp;&nbsp; <FontAwesomeIcon icon={faArrowRight} />&nbsp;&nbsp;</Button>
        </Link>
    </div>
  </Layout>
)

export default NotFoundPage
